<template>
    <div class="wrap" v-if="game">
        <div class="bg"></div>
        <div class="rule" @click="showRule = true"></div>
        <div class="close-game" @click="close">关闭</div>
        <!--砸蛋区域-->
        <div class="box">
            <p class="tips">余额<span>{{gold}}</span>,每次消费{{game.price}}</p>
            <ul class="egg clearfix">
                <li v-for="i in 6" :key="i">
                    <img :src="egg" class="goldegg init">
                    <img src="../../assets/egg/image/base.png">
                    <div class="info"></div>
                </li>
            </ul>
            <div id="hammer" class="shak"></div>
        </div>
        <audio id="audio" ref="audio">
            <source src="https://storage.wnwdkj.cn/egg_open.wav" type="audio/wav">
        </audio>
        <rule :content="game['remark']" v-show="showRule" @close="showRule = false"/>
        <prize v-if="showPrize" :prize="prize" @close="reset"/>
    </div>
</template>

<script>
    import '@/assets/egg/css/index.css'
    import rule from "@/components/rule";
    import egg from "@/assets/egg/image/egg.png"
    import step1 from '@/assets/egg/image/step1.png'
    import step2 from '@/assets/egg/image/step2.png'
    import step3 from '@/assets/egg/image/step3.png'
    import prize from "@/components/prize";
    import {Toast} from "vant";

    export default {
        name: "egg",
        components: {
            prize,
            rule
        },
        data() {
            return {
                egg: egg,
                showRule: false,
                showPrize: false,
                code: 'egg',
                gold: 0,
                game: null,
                prize: null,
            }
        },
        created() {
            this.$http.get('lottery-game/info', {params: {code: this.code}}).then((res) => {
                this.game = res.data;
                this.gold = res.data['gold']
                this.$nextTick(() => {
                    this.initJquery()
                })
            })
        },
        methods: {
            reset() {
                this.showPrize = false;
                $(".goldegg").each((i, item) => {
                    $(item).attr('src', egg)
                });
            },
            initJquery() {
                var that = this;
                /*轮流提示*/
                $(function () {
                    let $eggList = $(".egg li"),//金蛋父级
                        $tips = $(".info"),
                        $hammer = $("#hammer"),
                        $egg = $(".goldegg"),
                        rem = 75,
                        length = $egg.length;
                    //初始跳动
                    $egg.eq(length).addClass("jump");
                    $tips.eq(length).show();
                    setInterval(() => {
                        //金蛋跳动
                        length++;
                        length %= 9;
                        $egg.eq(length - 1 < 0 && 8 || length - 1).removeClass("jump");
                        $tips.eq(length - 1 < 0 && 8 || length - 1).hide();

                        $egg.eq(length).addClass("jump");
                        $tips.eq(length).show();
                    }, 1000);



                    $(document).on('click', '.egg li', function (item) {
                        if(!that.$refs.audio.ended){
                            that.$refs.audio.currentTime = 0
                        }

                        that.$refs.audio.play()
                        that.$refs.audio.pause()
                        eggChange($(this).index())
                    })

                    function eggChange(i) {
                        if (parseInt(that.gold) < parseInt(that.game.price)) {
                            return Toast.fail({message: '余额不足', position: 'bottom'})
                        }
                        let requestCompleted = false
                        let animateCompleted = false;

                        //砸蛋次数的变化
                        $hammer.removeClass("shak");//清除锤子晃动动画
                        //锤子砸蛋的位置
                        (i === 0 || i === 3 || i === 6) && ($hammer.css("left", 165 / rem + "rem"));
                        (i === 1 || i === 4 || i === 7) && ($hammer.css("left", 415 / rem + "rem"));
                        (i === 2 || i === 5 || i === 8) && ($hammer.css("left", 665 / rem + "rem"));
                        (i === 0 || i === 1 || i === 2) && ($hammer.css("top", 60 / rem + "rem"));
                        (i === 3 | i === 4 || i === 5) && ($hammer.css("top", 280 / rem + "rem"));
                        (i === 6 | i === 7 || i === 8) && ($hammer.css("top", 500 / rem + "rem"));

                        //锤子返回
                        setTimeout(function () {
                            $hammer.css("left", 665 / rem + "rem");
                            $hammer.css("top", 60 / rem + "rem");
                        }, 1500);

                        //金蛋破裂及锤子动画
                        setTimeout(function () {
                            $hammer.addClass("hit");
                            $egg.eq(i).prop("src", step1);
                            setTimeout(function () {
                                $egg.eq(i).prop("src", step2);
                            }, 300);
                            setTimeout(function () {
                                that.$refs.audio.play()
                                $egg.eq(i).prop("src", step3);
                                $egg.eq(i).removeClass("init");
                                $hammer.removeClass("hit");//清除锤子砸蛋动画
                                $hammer.addClass("shak");
                                setTimeout(function () {
                                    animateCompleted = true;
                                    that.shouldShowPrize(animateCompleted, requestCompleted)
                                }, 500)


                            }, 600);
                        }, 600);

                        that.$http.post('lottery-game/draw', {code: that.code}).then((res) => {
                            that.prize = res.data
                            that.gold = res.data['gold']
                            requestCompleted = true
                            that.shouldShowPrize(requestCompleted, animateCompleted)
                        }).catch(e => {
                            that.reset()
                            $hammer.css("left", 665 / rem + "rem");
                            $hammer.css("top", 60 / rem + "rem");
                        });

                    }
                });
            },
            shouldShowPrize(a, b) {
                if (a && b) {
                    setTimeout(() => {
                        this.showPrize = true;
                    }, 200)
                }
            }
        }
    }
</script>

<style scoped>

</style>
